@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  # * {
    @apply font-sans;
    font-family: "DM Sans", sans-serif;
  }
  .height-a {
    @apply h-[295px];
  }
  .select2-container {
    @apply relative z-10;
  }
  .select2-results__option {
    @apply flex items-center h-[50px] pl-4 pr-4 text-gray-900 cursor-pointer;
  }
  
  .select2-results__option--highlighted {
    @apply bg-indigo-600 text-white;
  }
  
  .select2-container--default .select2-selection--single {
    @apply flex items-center h-[50px] pl-4 pr-4 rounded-md;
  }
  
  .select2-container--default .select2-selection--single img {
    @apply w-5 h-5 rounded-full mr-3;
  }
  
  /* Select2 Dropdown */
.select2-container .select2-selection {
  @apply bg-white text-gray-900 cursor-default select-none py-2 pl-4 pr-9 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500;
}

/* Açılır Menüdeki Seçeneklerin Görünümü */
.select2-results__option {
  @apply flex items-center py-2 px-4 text-gray-900 cursor-pointer;
}

.select2-results__option--highlighted {
  @apply bg-indigo-600 text-white;
}

/* Seçili öğe görünümü */
.select2-container--default .select2-selection--single {
  @apply flex items-center pl-4 pr-4 py-2 rounded-md;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
}

/* Ülke Bayrağı için Stil */
.select2-results__option img,
.select2-container--default .select2-selection--single img {
  @apply w-5 h-5 rounded-full mr-3;
}

/* Boş sonuç mesajı */
.select2-results__message {
  @apply px-4 py-2 text-gray-500;
}

}
body {
  /* background: #eff2ee;*/
}
.MuiAccordionSummary-content {
  padding: 8px 0 !important;
}
@media only screen and (max-width: 767px) {
  .hidden-o {
    display: none;
  }
  .fl-x {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 991px) {
  .contact-page {
    margin: 0 20px !important;
  }
  .contact-f {
    display: grid !important;
    gap: 15px;
  }
  .gz-km .sub-right-on {
    display: grid !important;
    gap: 20px;
  }
  .gz-km .sub-right-on h4 {
  }
  .gz-km .right-on {
    padding: 19px 20px 0 !important;
  }
  .gz-km .sub-right-on {
    padding: 30px 0 !important;
  }
  .gz-km .sub-right-on div:last-child h4 {
    padding-left: 20px;
  }
  .gz-km .left-on {
    width: 100% !important;
  }
  .gz-km {
    display: grid !important;
  }
  .eightSection .item-eight {
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .eightSection .item-eight div {
    width: 50%;
  }
  .head-page h1 {
    font-size: 24px !important;
  }
  .head-page {
    border-radius: 0 !important;
  }
  .form-v {
    display: grid !important;
    gap: 50px !important;
  }

  .why-o-1 .grid-cols-1 .rounded-xl {
    min-height: auto;
  }

  .why-o-1 .grid-cols-1 .rounded-xl img {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
  }

  .block-tabs .hidden {
    display: none;
  }
  .head-page {
    background: #023e39 !important;
    margin-top: 0;
    text-align: center;
    margin-left: 0;
    border-radius: 0 !important;
    padding: 30px !important;
    margin-right: 0;
  }
  .head-page ul {
    justify-content: center;
  }

  .bg-hero-s {
    background-image: url("../public/assets/img/bg-slider-2.png") !important;
    background-color: #003e39;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .size-logo {
    width: 130px;
  }
  .rs-mt {
    border-radius: 30px;
    margin-top: -23px;
  }
  .solid-y {
    border: 1px solid #f7cc8d;
  }

  .mg-a {
    display: table;
    margin: auto;
  }
  .g-login {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr !important;
  }
  #customPagination {
    position: absolute;
    text-align: center;
    bottom: 47px;
  }
  #customPagination .swiper-pagination-bullet {
    background: rgb(255 255 255 / 78%);
    width: 20px !important;
    height: 20px !important;
  }
}

@media only screen and (min-width: 991px) {
  .h-other {
    display: none !important;
  }
}

@media only screen and (min-width: 767px) {
  .w-form-a {
    width: 700px;
  }
  .comment-h {
    /*  overflow: inherit !important;*/
  }
}

.sub-menu {
  position: absolute;
  top: 30px;
  padding: 9px 9px 2px;
  width: 100%;
  color: #023e39;
  background: #f3f3f3;
}
.sub-menu li {
  font-size: 14px;
}
.sub-menu li a {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 10px;
}
.sub-menu li a:hover {
  color: #f95d1f;
}

.tabs {
  cursor: pointer;
  display: flex;
  border-bottom: 2px solid #cfdcd0;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid #b5c3b6;
  padding: 17px 15px;
  position: relative;
  color: #003e39;
}
.tabs img {
  width: 20px;
}

.block-tabs .block::before {
  content: "\A";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f95d20 transparent transparent;
  position: absolute;
  transform: rotate(270deg);
  bottom: -17px;
  left: 46%;
}

footer li {
  list-style: none;
}

.f-list a:before {
  content: "";
  display: block;
  width: 1px;
  height: 18px;
  background: #003e39;
  right: -10px;
  top: 2px;
  position: absolute;
}
.f-list a:last-child::before {
  content: none;
}

.f-list a {
  position: relative;
}
.br-t {
  border-top: 1px solid #c2cdce;
  padding-top: 34px;
}
.MuiButtonBase-root {
  padding-left: 0 !important;

  padding: 5px 0 !important;
}
.MuiPaper-elevation {
  background: transparent !important;

  color: #003e39 !important;
  border-bottom: 1px solid #e5fffc !important;
  /* margin-bottom: 10px !important; */
  box-shadow: none !important;
  border: none !important;
}
.MuiAccordionDetails-root {
  font-size: 14px !important;
}
.MuiAccordionSummary-expandIconWrapper {
  background: #013e39;
}
.MuiAccordionSummary-expandIconWrapper svg {
  font-size: 11px;
  color: #fff;
  padding: 5px;
}

#customPagination .swiper-pagination-bullet-active {
  background: #fff;
  width: 12px;
  height: 12px;
}
#customPagination .swiper-pagination-bullet {
  background: rgb(255 255 255 / 78%);
  width: 12px;
  height: 12px;
}

.b-inp input::placeholder {
  font-size: 13px;
  color: #b1c7c4;
}
.bg-login {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/assets/img/hero-bg-2.png");
}
.top-head-page {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/assets/img/hero-bg-2.png");
  padding: 100px 0 58px;
  margin-top: -96px;
  width: 100%;
  top: 0;
}
.g-login {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 1.6fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
}
.bf-image::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  background-color: red;
}

.left-i::before {
  border-radius: 40px 40px 0 0;
  content: "";
  display: block;
  position: absolute;
  top: -22px;
  left: 0;
  width: 100%;
  height: 24px;
  background-color: #efeddb;
}
.size-logo {
  width: 150px;
}
.world-size {
  width: 21px;
}

.bg-hero-s {
  background-image: url("../public/assets/img/hero-bg.png");
}
.bg-hero-f {
  background-image: url("../public/assets/img/hero-bg-f.png");
  background-size: cover;
  background-position: center;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #333;
  color: #fff;
}

.menu-toggle {
  cursor: pointer;
  font-size: 1.5rem;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  padding: 0 1rem;
}

.sub-menu ul {
  display: none;
  position: absolute;
  background-color: #444;
  padding: 0.5rem 0;
  width: 150px;
}

.sub-menu:hover ul {
  display: block;
}

.sub-menu ul li {
  display: block;
}

@media screen and (max-width: 991px) {
  .list-country .list-l-a {
    width: 100% !important;
  }

  .minheight32{
    min-height: 32px;
     }

  .threSection ul {
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  .threSection li {
    width: 49%;

    box-sizing: border-box;
  }
  .threSection ul {
    gap: 50px;

    margin: 0 50px !important;
  }
  .oneSection h1 {
    font-size: 35px !important;
    margin-top: 20px;
  }
  .oneSection {
    display: grid !important;
  }
  .oneSection p {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .w-form-incv{
    max-width: 400px;
    overflow-y: scroll;
  }
  .twoSection img:last-child {
    max-width: 210px !important;
  }
  .m-page {
    margin: 0 20px;
  }

  .img-ref {
    margin-bottom: 19px;
  }

  .payment-form {
    width: 90% !important;
  }
  .rdp-weeknumber,
  .rdp-day {
    width: 28px !important;
    height: 28px !important;
    font-size: 13px !important;
  }
  .date-tolltake {
    padding: 6px !important;
  }
  .rdp-table {
    border-spacing: 4px !important;
  }
  .date-tolltake .rdp {
    margin: 0 !important;
  }

  .date-tolltake .rdp-head_row th {
    height: 4px !important;
    padding: 1px 0 !important;
    font-size: 10px;
  }
  .popup-contract {
    width: 90%;
  }
  .menu {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
    display: none;
    background-color: #333;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
  }

  .nav-links li {
    text-align: center;
    margin: 0.5rem 0;
  }

  .sub-menu ul {
    position: static;
    background-color: transparent;
    width: auto;
  }

  .sub-menu:hover ul {
    display: none;
  }

  .sub-menu ul li {
    display: none;
  }

  .sub-menu:hover ul li {
    display: block;
  }
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.border-header {
  border-bottom: 1px solid #ffffff24;
  padding-bottom: 14px;
}
.f-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
.step-toll .text-white {
  background-color: #d3fac7 !important;
  color: #0b3e38 !important;
  font-size: 17px !important;
  align-items: center;
  text-align: center;
}
.step-toll .place-items-center {
  width: 30px !important;
  height: 30px !important;
  background-color: #688261;
  color: #0b3e39;
  align-items: center;
  text-align: center;
  font-size: 17px;
}
.step-toll::before {
  display: inline-block;
  content: "";
  border-top: 1px solid #efeddb;
  width: 93%;
  margin: 0 1rem;
  position: absolute;
}
.step-toll {
  position: relative;
}
.plk-2 {
  background: #013399;
  color: #fff;
  padding: 0 7px 5px;
  border-radius: 7px 0 0 7px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
}
.plk input::placeholder {
  font-weight: 600;
}
.grid-b {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
.rd-inp input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #808285;
}

.br-none {
  border: 1px solid #023e39;
}
.date-tolltake .drop-shadow-sm {
  box-shadow: none;
  border-radius: 10px 10px 0 0;
  background: #efeddb;
  width: 100%;
  border: none;
}
.radius-card {
  border-radius: 0 0 10px 10px;
}
.date-tolltake .drop-shadow-sm .grid-cols-7 .w-12 {
  background: #fff;
  border: none;
}
.date-tolltake .drop-shadow-sm .text-lg {
  font-weight: 700;
}
.date-tolltake .justify-between .gap-x-2 button {
  background: #fff;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.19);
}
.date-tolltake .justify-between .gap-x-2 button svg {
  width: 20px;
  height: 20px;
}

.date-tolltake .drop-shadow-sm .mt-3 .w-12 {
  font-size: 12px;
  font-weight: 300;
  border-radius: 5px;
  padding: 5px 0;
  color: #979797;
}

.date-tolltake .ring-2 {
  box-shadow: none;
}

.date-tolltake button:hover {
  color: #f95d1f;
}
.date-tolltake button.text-white {
  background: #f95d1f !important;
  color: #000;
  border: 1px solid #f95d1f;
}
.is-hidden .icon-hide {
  display: none;
}
.is-shown .icon-show {
  display: none;
}
form .invalid {
  color: #be2b2b;
  font-size: 12px;
}
.form-auth .alert-danger {
  text-align: center;
  background: #be2b2b;
  color: #fff;
  font-size: 16px;
  padding: 14px 12px;
  border-radius: 10px;
}
.head-page {
  background: url("../public/assets/img/page-head.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 40px;
  border-radius: 20px;
}

.head-page h1 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 10px;
}
.head-page ul {
  display: flex;
  gap: 20px;
}
.head-page ul li {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.head-page ul li::after {
  content: ">";
  height: 5px;
  width: 5px;
  padding-left: 6px;
  position: absolute;
}
.head-page ul li:last-child::after {
  display: none;
}
.head-page ul li a {
}

.page-text * {
  color: #003e39;
}
.page-text {
  margin-top: 30px;

  margin-bottom: 100px;
}

.loader-div {
  max-width: 1140px;
  width: 100%;
}

ul,
li,
ol {
  list-style: none;
}
.card-loader {
  width: 100%;
  margin: 0 auto;
}

.card-loader {
  padding: 8px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
}
.card-loader:only-child {
  margin-top: 0;
}
.card-loader:before {
  content: "";
  height: 30px;
  display: block;
  background-color: #ccc;
  box-shadow: -68px -98px 0 -48px #ededed, -50px 110px 0 -48px #ededed;
}
.card-loader:after {
  content: "";
  background-color: #333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.f-link a {
  --tw-text-opacity: 1;
  color: rgb(0 62 57 / var(--tw-text-opacity));
  font-weight: 300;
  font-size: 15px;
  display: block;
  margin-bottom: 1rem;
}
.bg-hero-p {
  position: fixed;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.rdp-day_selected {
  background: #f95d1f !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.rdp-cell button {
  border-radius: 5px !important;
  color: #000;
  margin-bottom: 10px;
  background: #fff;
}
.rdp-cell button:hover {
  background: #f95d1f !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.rdp-head_row th {
  background: #fff;
}
.date-tolltake {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  background-color: #efeddb;
}

.date-tolltake .rdp {
  margin-bottom: 0;
}
.date-tolltake .rdp-table {
  max-width: 100%;
  width: 100%;
}
.rdp-months {
  display: grid !important;
}

.rdp-table {
  border-collapse: separate !important;
  border-spacing: 15px;
}
.rdp-head_row th {
  color: #07070778;
  border-radius: 10px;
  height: 5px;
  padding: 5px;
}
.br-l {
  border-left: 1px solid #003e39;
}
.l-hov li:hover {
  color: #000;
}
/*
.bg-home{
  background: #F1F4F0;
}
.bg-how{
  background: #d7dede;
}
.bg-dash{
  background: #F4F5F3;
}
*/
.w-form-a .bg-indigo-600 {
  background: transparent !important;
}
.w-form-a button.transition-all {
  background: #d3fac7 !important;
  color: #023e39;
  padding: 3px 12px;
  margin-right: 17px;
}
.step-toll .bg-gray-300:first-child {
  background: transparent !important;
}
.step-toll .bg-gray-900:nth-child(2) {
  background: transparent !important;
}
.hov-a li:hover {
  color: #f95d1f !important;
  background: white;
}
#link-checkbox:checked::before {
  background-color: #f95d1f;
  content: "\2713";
  position: absolute;
  height: 19px;
  top: -1px;
  left: -2px;
  display: flex;
  border-radius: 4px;
  font-size: 12px;
  width: 19px;
  color: #fff;
  align-items: center;
  justify-content: center;
}
#sms-checkbox:checked::before {
  background-color: #f95d1f;
  content: "\2713";
  position: absolute;
  height: 19px;
  top: -1px;
  left: -2px;
  display: flex;
  border-radius: 4px;
  font-size: 12px;
  width: 19px;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
}

.popup-contract {
  position: fixed;
  background: #fff;
  top: 50%;
  z-index: 99999;
  border-radius: 15px 0 0 15px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-contract::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.popup-contract .content {
  padding: 25px;
  height: 600px;
  width: 100%;
  overflow-y: scroll;
  border-radius: 10px;
}

.popup-contract .content p {
  font-weight: 400;
  font-size: 14px;
}

.popup-contract .content h2 {
  margin: 20px 0 0px;
  font-weight: 600;
  font-size: 26px;
}

.popup-contract .content h3 {
  font-weight: 600;
  font-size: 24px;
}
.popup-contract .content h4 {
  font-weight: 600;
  font-size: 22px;
}

.popup-contract .content::-webkit-scrollbar {
  width: 3px;
}

.popup-contract .content::-webkit-scrollbar-track {
  background: #ededed;

  border-radius: 10px;
}

.popup-contract .content::-webkit-scrollbar-thumb {
  background: #f95d1f;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;

  left: 0;
  top: 0;
  background-color: rgba(34, 34, 34, 0.85);
}
.close-contract {
  cursor: pointer;
  right: 0;
  position: absolute;
  top: -40px;
  float: right;
  color: #fff;
}
.message-i {
  color: #f95d20;
  font-weight: 300;
  margin-top: 10px;
}
.five-alert .p-color {
  fill: #f95d1f;
}
.five-alert {
  position: fixed;

  top: 50%;
  z-index: 99999;
  border-radius: 15px 0 0 15px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.payment-alert .p-color {
  fill: #f95d1f;
}
.payment-alert {
  text-align: center;
  z-index: 99999;
}
.bg-color-122 {
  fill: #ffffff24;
}

.exit-buy {
  color: #023e39;
  position: absolute;
  background: #d3fac7;
  right: 13px;
  border: 1px solid #fff;
  top: 70px;
  border-radius: 21px;
  padding: 2px 4px;
}

.exit-buy:hover {
  background: #fff;
  color: #0c3e39;
}

.exit-buy svg {
  width: 19px;
}

.u-name {
  padding: 13px 19px;
  background: #d1fdac;
  border-radius: 100px;
  border: 1px solid;
}
.u-name span {
  color: #013b36;
  font-weight: 600;
}
.link-home-a a {
  padding: 10px 40px;
}
.close-list-a {
}
.close-list-a svg {
  color: #fff;
  background: #f95d1f;
  padding: 5px;
  border-radius: 20px;
}
.border-gray-300 {
  border-color: #b9c3c2;
}
.form-invoice {
  margin-top: 15px;
}

.form-invoice label {
  font-weight: 400;
  display: block;
  font-size: 13px;
  color: #003e39;

  margin-bottom: 2px;
}

.form-invoice input {
  width: 100%;
  font-size: 13px;
  border-radius: 10px;
  padding: 10px;
}
.form-invoice select {
  width: 100%;
  font-size: 13px;
  border-radius: 10px;
  padding: 10px;
}
.form-invoice h3 {
  color: #003e39;
  margin-bottom: 10px;
  font-weight: 600;
}

.form-invoice hr {
  color: #4b4b4b21;
  margin: 20px 0px;
  font-weight: 600;
}
.border-list .border-b:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.alet-baset-empty {
  olor: #0c3e39;
  align-items: center;
  display: grid;
  text-align: center;
  justify-content: center;
  margin: 13px 0;
  font-size: 17px;
  font-weight: 500;
}
.alet-baset-empty span {
  display: block;
  font-size: 28px;
  color: #0c3e39;
}
.alet-baset-empty div {
  display: block;
  font-weight: 300;
  color: #0c3e39;
  font-size: 16px;
}
.alet-baset-empty svg {
  display: table;
  margin: auto;
  width: 50px;
  fill: #0c3e39;
  height: 50px;
}

.payment-form {
  position: fixed;
  padding: 30px;
  width: 700px;
  background: #fff;
  top: 50%;
  z-index: 999;
  border-radius: 33px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.payment-form h3 {
  font-size: 12px;
  color: #f95d18;
  margin-bottom: 5px;
  font-weight: 600;
}

.payment-form input {
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #003e3959;
  padding: 10px;
}
.payment-form input:focus {
  border: 1px solid #003e39;
}
.payment-form label {
}
.payment-form label img {
  position: absolute;
  right: 12px;
  width: 80px;
  top: 17px;
}

.payment-form .item-payment {
  position: relative;
}
.item-payment span {
  color: #003e39;
}
.payment-form .item-o {
  margin-bottom: 12px;
}
.link-close-payment {
  position: absolute;
  right: 19px;
  top: -38px;
  color: #fff;
}
.py-header {
  display: flex;
  gap: 7px;
  font-weight: 600;
  justify-content: center;

  font-size: 27px;
  align-items: center;
  color: #003e39;
}
.ccv-card {
  top: 12px !important;
  width: 30px !important;
}
.pay-total {
  color: #003e39;
  font-size: 34px;
  font-weight: 300;
  text-align: center;
}
.overlay-payment {
  width: 100%;
  height: 100%;
  background: #ccc;
  position: absolute;
  z-index: 99;
}
.faq-page {
  padding-top: 20px;
}
.bg-hero-s .pay-total {
  font-size: 17px !important;
  font-weight: 400 !important;
  text-align: left;
}

.p-country {
  top: -110px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding-right: 19px;
  right: 0;
  position: absolute;
}
.p-country .tabs {
  border-right: none;
}

.sub-country {
  top: -112px;
  border-radius: 10px 10px 0 0;
  background: #cfdcd0;
  position: absolute;
  right: 9px;
}
.sub-country .hidden {
  display: none;
}
.sub-country .tabs {
  border: none;
  border-bottom: 1px solid #b5c3b6;
}
.about-b p {
  margin-bottom: 12px;
}
.list-m li img {
  position: absolute;
  left: 0;
  width: 20px;
}
.list-m li a {
  padding-left: 35px;
}
.ref-img img {
}
.img-ref {
  display: grid;
  position: relative;
  height: 55px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ref-img {
  z-index: -1;
  position: relative;
}
.inp-on {
}
.react-tel-input .flag-dropdown .selected-flag {
  background-color: #fff;
  border-bottom: 1px solid #0c3e39 !important;
  height: 35px;
  border-left: 1px solid #0c3e39 !important;
  border-top: 1px solid #0c3e39 !important;
  position: relative;
  top: -1px;
  border-radius: 10px 0 0 10px !important;
}
.react-tel-input .form-control {
  font-weight: 600;
  color: #0c3e39;
  border: 1px solid #0c3e39 !important;
  border-radius: 10px !important;
}
.inp-on label {
  font-size: 12px;
  color: #0c3e39;
  margin-bottom: 3px;
  display: inline-block;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
  border-right: none;
  border-radius: 10px !important;
}
.why-vn-1 h3 {
  font-size: 20px;
  font-weight: 500;
}
.why-vn-1 p {
  font-size: 14px;
}
.why-vn-1 .height-a {
  padding: 32px 24px 50px;
}
.sz-vn-1 {
  font-weight: 300;
  line-height: 26px;
  font-size: 16px;
}

.v-control h1 img {
  width: 30px;
}

.v-control .bg-w {
  background: #e7ebeb;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  padding: 40px 25px 80px;
  border-radius: 10px;
}
.v-control .plk input {
  border: 1px solid #ccc;
}

.v-control .b-button {
  border: 1px solid #ccc;
}
.item-sw {
  min-height: 300px;
}
.why-o-1 .grid-cols-1 .rounded-xl {
  min-height: 670px;
  position: relative;
}
.form-v {
  display: flex;
  gap: 100px;
}

.why-o-1 .grid-cols-1 .rounded-xl img {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

.input-f {
  position: relative;
  margin-top: 50px;
}

.w-flag-a img {
  width: 20px;
}

.input-f img {
  position: absolute;
  left: 17px;
  width: 20px;
  top: 15px;
}
.input-f label {
  display: inline-block;
  color: #003e39;
  margin-bottom: 17px;
}
.input-f label span:nth-child(1) {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  background: #00554e;
  padding: 5px 12px;
}
.input-f label span:nth-child(2) {
  font-weight: 500;
  padding-left: 20px;
  font-size: 16px;
}
.w-flag-a {
  font-size: 14px;
}
.plk-f input {
  font-size: 16px;
  width: 100%;
  padding: 14px 52px;
  font-weight: 600;
  border-radius: 5px;
}
.plk-f input::placeholder {
  color: #003e39;

  font-size: 14px;
}

.mt-0-1 {
  margin-top: 0 !important;
}

.oneSection {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  align-items: center;
}

.oneSection h1 {
  font-weight: 700;
  font-size: 55px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #003e39;
}

.oneSection p {
  color: #003e39;
  font-size: 18px;
}
.oneSection a {
  background-color: #003e39;
  padding: 15px 30px;
  font-weight: 500;
  border-radius: 15px;
  display: inline-block;
  color: #fff;
}
.oneSection a:hover {
  background-color: #f95d1f;
}

.twoSection img:first-child {
  border-radius: 20px;
}
.twoSection img:last-child {
  position: absolute;
  bottom: 12px;
  left: 29px;
  max-width: 380px;
}
.twoSection {
  position: relative;
}
.threSection {
  margin-top: 50px;
  margin-bottom: 50px;
}
.threSection div:first-child {
  font-size: 16px;
  color: #003e39;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 300;
}
.threSection ul {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 150px;
}
.threSection li {
}

.threSection li img {
}
.threSection p {
  margin-top: 50px;
  font-size: 16px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}
.four-sub .list-l-a {
  display: flex;
  align-items: center;
  gap: 15px;
}
.four-sub .list-l-a img {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}
.list-l-a .list-img {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-l-a .list-img img {
  width: 50%;
  padding: 2px;
  box-sizing: border-box;
}
.list-l-a h3 {
  color: 003e39;
  font-size: 16px;
  font-weight: 300;
}
.list-l-a p {
  color: 003e39;
  font-size: 18px;
  font-weight: 300;
}

.list-l-a div:first-child {
  max-width: 55px;
}
.list-country {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: 50px;
}

.list-country .list-l-a {
  margin-bottom: 33px;
  width: 50%;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #d6d6d6;
}

.four-sub {
  background: #f7f8f6;
  padding: 40px;
}

.four-sub p {
  font-size: 16px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}

.four-sub h3 {
  font-size: 18px;
  margin-top: 20px;
  color: #003e39;
  font-weight: 700;
}

.fourSection h2 {
  font-size: 24px;
  color: #003e39;
  margin-bottom: 30px;
  font-weight: 700;
  padding-left: 60px;
}

.fourSection h4 {
  font-size: 16px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}

.fiveSection {
  margin-top: 30px;
}

.fiveSection h2 {
  font-size: 24px;
  color: #003e39;
  margin-bottom: 30px;
  font-weight: 700;
  padding-left: 60px;
}

.fiveSection .five-sub {
  background: #efefe2;
  padding: 40px;
}

.fiveSection .five-sub p {
  font-size: 16px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}
.fiveSection .five-sub h4 {
  font-size: 16px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}
.fiveSection .five-sub h3 {
  font-size: 18px;
  margin-top: 20px;
  color: #003e39;
  margin-bottom: 10px;
  font-weight: 700;
}
.fiveSection .five-sub .item-five div:first-child {
  background: #153770;
  color: #fff;
  font-size: 21px;
  display: inline-block;
  border-radius: 3px;
  font-weight: 600;
  padding: 0px 19px;
}
.fiveSection .five-sub .item-five {
  display: flex;
  gap: 20px;
  margin-top: 25px;
  align-items: self-start;
}
.fiveSection .five-sub .item-five p {
}
.fiveSection .five-sub .item-five h4 {
}
.fiveSection .five-sub .item-five img {
  width: 13px;
  display: inline-block;
  margin-right: 10px;
}

.sixSection {
  margin-top: 30px;
}
.sixSection h2 {
  font-size: 24px;
  color: #003e39;
  margin-bottom: 30px;
  font-weight: 700;
  padding-left: 60px;
}
.sixSection .itemSix {
  border: 1px solid #0e2727;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.sixSection img {
  background: #003e39;
  border-radius: 5px;
  margin-right: 5px;
  display: inline-block;
  width: 40px;

  padding: 5px;
}
.sixSection p {
  font-size: 16px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
  margin: 16px 0;
}
.gz-km {
  display: flex;
  margin-top: 50px;
}
.gz-km .left-on {
  width: 50%;
  background-color: #fff4e5;
  padding: 35px;
}

.gz-km .left-on h2 {
  font-size: 26px;
  color: #003e39;

  font-weight: 700;
}
.gz-km .left-on p {
  font-size: 14px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}
.gz-km .right-on {
  width: 100%;
  background-color: #003e39;
  padding: 59px 40px 0;
}
.gz-km .right-on {
  width: 100%;
  background-color: #003e39;
}
.gz-km .pl-22 {
  padding-left: 20px;
}
.gz-km .sub-right-on {
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  padding: 40px 40px 100px;
  background: #fff;
}
.gz-km .sub-right-on h4 {
  margin-bottom: 15px;
  background-color: #d5ecd3;
  color: #003e39;
  width: 320px;
  padding: 11px 0;
  font-size: 15px;
  font-weight: 500;
}
.gz-km .sub-right-on div:first-child h4 {
  padding-left: 20px;
}
.gz-km .sub-right-on p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #003e39;
}

.gz-km .sub-right-on .sub-right {
  border-top: 1px solid #0e2727;
  margin-top: 20px;
  padding-top: 20px;
}
.eightSection {
  margin-top: 50px;
  margin-bottom: 50px;
}
.eightSection h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #003e39;

  font-weight: 600;
}
.eightSection p {
  font-size: 14px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}
.eightSection .item-eight {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}
.eightSection h2 {
  margin-top: 20px;
}
.eightSection .item-eight div {
  border-radius: 5px;
  background-color: #f7f8f6;
  width: 107px;
  height: 70px;
  border: 1px solid #0e2727;
  display: flex;
  align-items: center;

  justify-content: center;
}
.eightSection .item-eight div p {
  font-weight: 600;
  font-size: 12px;
}
.sevenSection h2 {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 18px;
  color: #003e39;

  font-weight: 600;
}
.sevenSection p {
  font-size: 14px;
  color: #003e39;
  line-height: 25px;
  font-weight: 300;
}
.head-cont {
  margin-top: 10px;
}
.head-cont h1 {
  font-weight: 600;
  font-size: 55px;
  color: #003e39;
}
.head-cont p {
  font-size: 16px;
  margin-bottom: 25px;
  color: #003e39;
}
.contact-f {
  display: flex;
  gap: 15px;
}
.contact-f div {
  width: 100%;
  padding: 30px;
}
.bg-1-f {
  background-color: #ffffff;
}
.bg-2-f {
  background-color: #ffffff;
}
.contact-f h2 {
  font-size: 24px;
  margin-top: 5px;
  font-weight: 600;
  color: #003e39;
}
.bg-1-f p {
  color: #003e39;
  font-weight: 300;
  font-size: 16px;
  margin: 10px 0 20px;
  max-width: 300px;
}
.bg-2-f p {
  color: #003e39;
  font-weight: 300;
  font-size: 16px;
  margin: 10px 0 20px;
}
.bg-1-f ul {
}
.bg-1-f ul li {
  margin-bottom: 20px;
}
.bg-1-f ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #003e39;
}

.bg-1-f ul li a img {
  width: 20px;
}

.bg-1-f .one-img-f {
  width: 30px;
}

.bg-2-f label {
  color: #003e39;
  font-size: 12px;
  margin-bottom: 6px;
  display: inline-block;
}
.form-o {
  padding: 0 !important ;
}
.bg-2-f input {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #013e39;
  padding: 10px 20px;
  border-radius: 5px;
}
.bg-2-f textarea {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #013e39;
  padding: 10px 20px;
  border-radius: 5px;
}
.bg-2-f input::placeholder {
  font-size: 12px;
}
.bg-2-f textarea::placeholder {
  font-size: 12px;
}
.bg-2-f button {
  background: #f95d1f;
  padding: 10px 55px;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
}
.contact-page {
  margin-bottom: 50px;
}
.rs-hid {
  overflow: hidden;
}
.err-payment {
  color: #f95d18;
  font-size: 13px;
  margin-bottom: 10px;
}

.err-payment span {
  color: #f95d18;
  font-weight: 600;
}
.req-form span {
  color: #f95d1f;
}
.flag-img-select img {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}

.flag-img-select span {
  display: flex;
  gap: 10px;
  align-items: center;
}
.mx-w-home {
}
.hov-a {
  width: inherit;
}


.top-ord{
  background: #003e39;
  padding: 10px;
  z-index: 999999999;
  color: white;
  position: relative;
  border-radius: 1000px;
}

.form-auth .alert-success{
  text-align: center;
  background: #0c3e39;
  color: #fff;
  font-size: 16px;
  padding: 14px 12px;
  border-radius: 10px;
}

.list-m svg{
  position: absolute;
  right: 0;
}
.errr-card{
  color: #e40521;
  margin-bottom: 12px;
  font-size: 14px;
  display: flex;
  margin-top: 0;
  gap: 5px;
  align-items: center;
}

.errr-card svg{
  width: 20px;
}
.block-tabs .block{
  border-bottom: 2px solid #f35b1f;
  background: #fff;

}

.invoice-form {
  margin: 50px 0 15px;
}

.invoice-form .form-input {

  
}
.invoice-form .form-input input{
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  background-color: #e7ebe4;
}
.invoice-form .form-input select{
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  background-color: #e7ebe4;
  color: #003e39;
  font-weight: 200;
}
.invoice-form .form-input input::placeholder{
  color: #003e39;
  font-weight: 200;
}
.invoice-form .form-input label{
  color: #003e39;
  font-size: 14px;
  margin-bottom: 6px;
  display: inline-block;


  
}

.bt-dash{
  display: block;
  padding: 10px 30px 10px 14px;
  border-radius: 5px;
  width: 100%;
  background-color: #e7ebe4;
  color: #003e39;
  font-weight: 200;
}
.submit-form-dash button:hover{
background: #f95d1f;

}
.submit-form-dash button{
  display: flex;
  color: #fff;
  padding: 11px 18px;
  font-size: 15px;
  height: 45px;
  background: #003e39;
  font-weight: 500;
  bottom: 0;
  position: absolute;
  border-radius: 5px;
}
.submit-form-dash{
  position: relative;
}

.submit-form-dash svg{

  width: 18px;
      display: inline-block;
      margin-right: 8px;
}
.invoice-tab thead{
  background: #e7ebe4;
  border: none;
}

.invoice-tab thead th{ 

  border: none;

}

.invoice-tab thead th p{ 
color: #003e39;;

  font-weight: 500;
}
.down-link{
  background-color: #e7ebe4;
  font-size: 12px;
  border: 1px solid #003e39;
  display: inline-block;
  padding: 7px 10px;
  border-radius: 5px;
}
.down-link:hover{
  background: #003e39;
  color: #fff;
}
.invoice-tab tbody td{
  border-bottom: 1px solid #e7ebe4;
}
.detail_bottom {
  display: flex;
  gap: 0;
  justify-content: space-between;
 
}
.detail_bottom div span{
  display: block;
  font-size: 12px;
}
.detail_bottom div b{

  font-size: 14px;
}

.assist{
    
  
}
.assistlist{
    
}


